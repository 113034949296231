.drawer {
    position: fixed;
    z-index: 999;
    height: 100%;
    right: 0;
    overflow: auto;
    transition: .3s;
}

.subLinksContainer {
    display: flex;
    margin-left: 30px;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.clickOutside {
    position: relative;
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 500;
    color: #d8d8d8;
    flex: 1 1 50%;
    text-decoration: none;
    padding: 2px 8px;
    margin: 0;
}

.clickInside {
    position: relative;
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 500;
    color: #d8d8d8;
    flex: 1 1 50%;
    text-decoration: none;
    padding: 2px 8px;
    margin: 0;
}

.clickOutside:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    margin-right: 20px;
    transform-origin: bottom left;
    transform: skew(-30deg, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.clickInside:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: -9px;
    margin-right: 20px;
    transform-origin: center;
    transform: skew(-10rad, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.clickInside:hover > a,
.clickOutside:hover,
.headingLinkContainer:hover > a,
p.navItemClickable:hover {
    color: #000 !important;
    opacity: 1 !important;
}

.heading {
    padding: 10px;
}

.menuContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.drawer > div {
    background: #000;
    height: 100%;
    margin-left: 70%;
    opacity: 0.99;
    overflow: auto;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: .5rem 1rem;
    margin-top: 50px;
}

.navItem {
    font-family: var(--font-primary);
    font-size: 19px;
    font-weight: 800;
    color: #fff;
    opacity: 0.5;
    text-transform: uppercase;
    transition: .3s;
    text-decoration: none;
    width: 100%;
    margin: 0;
    padding-left: 14px
}

.navItemClickable {
    font-family: var(--font-primary);
    font-size: 19px;
    font-weight: 800;
    color: #d8d8d8;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    margin: 0;
    padding: 4px 0;
    opacity: 0.5;
    display: block;
}

.navItemClickable:hover::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: -18px;
    transform-origin: bottom left;
    transform: skew(-10rad, 0deg);
    z-index: -1;
    background: linear-gradient(to bottom, #b1fd2b, #31eb6f);
}

.imgContainer1 {
    height: 25px;
    width: 20px;
    position: relative;
}

.gameRow {
    width: 100%;
    padding: 5px;
    align-items: center;
}

.imgCol {
    padding-bottom: 2px;
    max-width: 20px;
}

.headingLinkContainer {
    max-width: 250px;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 2px 15px;
}

@media screen and (max-width: 1200px) {
    .drawer > div {
        margin-left: 50%;
    }
}

@media screen and (max-width: 767px) {
    .drawer > div {
        margin-left: 20%;
    }

    .clickInside {
        padding: 2px 8px;
        font-size: clamp(12px, 3vw, 55px);
    }

    .clickOutside {
        font-size: clamp(12px, 3vw, 55px);
    }

    .navbar {
        padding: 0;
    }

    .navItem {
        font-size: 16px;
    }

    .navItemClickable {
        font-size: 16px;
    }

    .subLinksContainer {
        margin-bottom: 5px;
    }
}
